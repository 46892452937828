.Pricing{
    padding-top: 70px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.pricingHeader{
    color: #041F3E;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 70px;
    font-family: "Poppins";
    font-weight: 420;
    line-height: 1.1em;   
    margin-bottom: 50px;
}


.PricingTable{
    width: 1400px;
    display: flex;
    flex-direction: row;
    gap:10px;
    align-items: center;
    justify-content: center;
}

.pricingFlex1{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    margin-left: 50px;
}

.pricingFlex2{
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
}

.pricingOrText{
    font-size: 30px;
    padding: 5px;
    font-weight: 600;
    color: #041F3E;
}

.PricingInfo{
    width:400px;
    border: 2px solid black;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 45px 5px 15px 5px;
    color: #041F3E;
}

.chatbotitButton{
    margin-top: 40px;
    width: 200px;
    height: 60px;
    background-color: #041F3E;
    border-radius: 30px;
    display: flex;
    justify-content: center;
    gap:10px;
    align-items: center;
    color: white;
    font-size: 22px;
    line-height: 1.5em;
    font-family: "Poppins";
    font-weight: 400;
    cursor: pointer;
    margin-bottom: 20px;
}

.pricingInfoText{
    width: 80%;
    text-align: left;
    line-height: 1.7em;
    font-family: "Poppins";
    font-size: 19px;
    font-weight: 370;

}

.PricingBox{
    width:280px;
    height: 270px;
    border: 1px solid black;
    border-radius: 25px;
    background-color: #041F3E;
    color:white;
}


.PricingBox1{
    height: 270px;
    width: 582px;
    border: 1px solid black;
    border-radius: 25px;
    background-color: #041F3E;
    color:white;
    display: flex;
    flex-direction: row;
    gap:80px;
    padding: 0px 30px 0px 30px;
    font-family: "Poppins";

}

.PricingBox1 > div:nth-child(2){
    display: flex;
    flex-direction: column;
    gap:10px;
    padding-top: 10px;
    width:250px;
    text-align: start;
    font-size: 17px;
    
}
.PricingBox1 > div:nth-child(2) >div:nth-child(1){
    font-weight: 500;
    margin-left: 0;
}
.startinPriceText{
    margin-left: 10px;
    display: flex;
    flex-direction: row;
    gap:5px;
}

.text1{
    font-family: "Poppins";
    margin-top: 20px;
    font-size: 50px;

}


.text2{
    font-family: "Poppins";
    margin-top: 20px;
    font-size: 35px;
    
}
.pricingIcon{
    margin-top: 30px;
    margin-bottom: 22px;
    transform: scale(3);
}

@media screen and (max-width: 1050px) {
.PricingTable{
    flex-direction: column;
}

}


@media screen and (max-width: 720px) {
    .PricingBox1{
        flex-direction: column;
        width: 90%;
        height: 550px;
        gap:20px;
        width: 75%;
        padding: 0;
        align-items: center;
    }
    .PricingBox{
        width: 75%;

    }
    .pricingFlex1{
        margin-left: 0;
        width: 100%;

    }
    .pricingFlex2{
        flex-direction: column;
        width: 100%;

    }

    .Pricing{
        padding-bottom: 100px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .PricingTable{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap:30px;
        width: 100%;
        margin-left: 0;

    }
    .pricingInfoText{
        line-height: 1.5em;
        font-size: 17px;
        width: 87%;
    }

    .PricingInfo{
        position: initial;
        width: 88%;
        margin: 0;

    }
    .Pricing1{
        position: initial;
    }
    .Pricing2{
        position: initial;
    }

    .pricingHeader{
        font-size: 50px;
        width: 70%;
    }



}