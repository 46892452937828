.ChatBots{
    margin-top: 100px;
    width: 100%;
    display: flex;
    flex-direction: row;
    border-bottom: 2px solid black;
    padding-bottom: 50px;
}

.chatBotsFirstHalf{
    display: flex;
    flex-direction: column;
    width: 50%;
    align-items: center;
    justify-content: flex-start;
    margin-left: 30px;
    
}

.chatBotsSecondHalf{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.chatBotPic{
    width: 76%;
    border: 0.7px solid grey;
}

.chatBotTitle{
    font-size: 60px;
    font-weight: 430;
    font-family: "Poppins";
    text-align: start;
    margin: 0;
    margin-left: 20px;
    color:#041F3E;

}


.chatBotText{
    margin-top: 30px;
    font-size: 21px;
    font-weight: 370;
    font-family: "Poppins";
    width: 100%;
    text-align: start;
    margin-left: 20px;
    color:#041F3E;

}



.chatBotButton{
    width: 20px;
    height: 20px;
    background-color: black;
}


.dashText{
    font-family: "Poppins";
    font-size: 23px;
    font-weight: 370;
    color:#041F3E;
    margin-top: 20px;
    width: 70%;
    text-align: start;
}

@media screen and (max-width: 720px) {

    .dashText{
        font-size: 18px;
        margin-top: 20px;
        width: 70%;
    }

    .ChatBots{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .chatBotsFirstHalf{
        width: 100%;
        margin: 0;
    }

    .chatBotText{
        width: 75%;
        text-align: start;
    }
    .chatBotsSecondHalf{
        width: 100%;
        align-items: center;
        margin-top: 20px;
    }

    .chatBotTitle{
        font-size: 40px;
        margin: 0;
        width: 85%;
        color:#041F3E;

    }
    .chatBotText{
        width: 85%;
        text-align: start;
        font-size: 18px;
        font-weight: 370;
        margin: 0;
        margin-top: 20px;
        color:#041F3E;
    }
    .chatBotPic{
        width: 300px;
    }
    
}