
.notFound1{
    height: 600px;
    color: black;
    display: flex;
    align-items: center;
    justify-content: center;
}


.notFound2{
    padding: 40px;
    border: 1px solid black;
}
