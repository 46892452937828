.chartHolder{
    width: 50%;
    margin: 10px;
    display: flex;
    flex-direction: column;
}

.chartHolder select{
    max-width: 100px;
    padding: 3px;
    border: 1px solid grey;
    border-radius: 2px;
}