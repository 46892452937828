.Contact{
    padding-top: 80px;
    width: 100%;
    display: flex;
    justify-content: center;
    padding-bottom: 80px;
    min-width: 500px;
    text-align: center;
}

.ContactLogo{
    width: 50%;
    height: 100%;
}


.contactTab{
    width:40%;
    min-width: 400px;
    max-width: 600px;
    height: 87%;
    border: 2px solid #041F3E;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    padding-bottom: 30px;
    align-items: center;
}


.ContactLogoText{
    font-family: "Poppins";
    font-size: 90px;
    font-weight: 400;
    line-height: 1.1em;
    color: #041F3E;
}
.contactIcon{
    margin-top: 180px;
    transform: scale(20);
    color: #041F3E;
    font-size: 15px;

}


.contactTab button{
    width:180px;
    height: 50px;
    background-color: #041F3E;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    margin-top: 5px;
    font-family: "Poppins";
    cursor: pointer;
    font-size: 20px;
    border: none;
}


.contactTab input{
    width: 90%;
    height: 30px;
    font-size: 16px;
    margin-top: 6px;
    border:1px solid #4F4F4F
}



.contactTab textarea{
    width: 90%;
    height: 100px;
    font-size: 13px;
    margin-top: 6px;
    resize: none;
    border:1px solid #4F4F4F

}

.contactTab div{
    text-align: start;
    margin-top: 20px;
    font-family: "Poppins";
    font-weight: 300;
    font-size: 17px;
    width: 90%;
}



.sentMsg{
    margin-top: 18px;
    font-size: 20px;
}

@media screen and (max-width: 720px) {

    .Contact{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        min-width: auto;
    }

    .ContactLogoText{
        font-size: 40px;
    }
    .contactIcon{
        margin-top: 50px;
        transform: scale(6);
        color: #041F3E;
    }
    .contactTab{
        min-width: auto;
        margin-top: 50px;
        width: 90%;
        margin-bottom: 90px;

    }
}
