.StartLogo{
    width: 100%;
    display: flex;
    color:white;
    font-size: 29px;
    padding-top: 60px;
    font-family: "Poppins";
    overflow: auto;
    z-index: 2;
    background: linear-gradient(to right,#041F3E,#40526B,#BAC0C9);
    min-height: 650px;
    align-items: center;
}


.startdiv1{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 10%;
    gap:40px;
    flex-grow: 0.5;
    padding-top: 20px;
}

.startLogoPic{
    width: 350px;
}



.startInfoTable{
    display: flex;
    flex-direction: column;
    width: 460px;
    padding-left: 10%;
    padding-right: 20%;
    padding-top: 10px;
}

.startSometi{
    width: 60%;
    flex-grow: 1;

}

.startimgContainer {
    width: 5%;
    display: flex;
    flex-direction: column;
    gap:15px;
    flex-grow: 0.5;
    align-items: flex-end;
    padding-right: calc(9% - 100px);
    padding-top: 30px;

}

.startimgContainer img{
    width: 260px;
    height:auto;
    border-radius: 5px;
}


.startimgContainer  div{
    border-radius: 7px;
    height: 38px;
    color: white;
    background-color: #041F3E;
    border: none;
    cursor: pointer;
    font-size: 15px;
    font-weight: 410;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
    width: 260px;
}




.fullInfoStartTable{
    margin-bottom: 40px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap:15px;
}

.oneStartTable > div:nth-child(1){
    margin-bottom: 5px;
    font-size: 28px;
    font-weight: 500;
    text-align: start;
}

.oneStartTable > div:nth-child(1) span{
    color:  #cfe4fc ;
    font-size: 110%;
}

.oneStartTable > div:nth-child(2){
    display: flex;
    flex-direction: row;
    gap: 10px;
    font-size: 20px;
    color:  #e2e5e9;

}

.startPageIcon{
    font-size: 32px;
    margin-top: 6px;
    min-width: 46px;
}

.startInfoTable > .fullInfoStartTable:nth-child(3) .startPageIcon{
    font-size: 50px;
    margin-top: -2px;
    margin-right: -1px;
}

.oneStartTable > div:nth-child(2) span{
    text-align: start;
    width: 90%;
}

.startdiv1 h1{
    font-weight: 450;
    font-size: 30px;
    margin:0;
    margin-bottom: 40px;
    width: 85%;
}




@media only screen and (max-width: 1000px) {
    .oneStartTable > div:nth-child(2){
        margin-left: 15px;
    }

    .startdiv1 img{
        width: 70%;
    }
    .StartLogo{
        flex-direction: column;
        align-items: center;
        background: linear-gradient(to bottom,#041F3E,#40526B,#BAC0C9);
    }
    .startInfoTable{
        margin: 0;
        width: 90%;
        padding: 0;
        margin-left: 10px;

    }
    .startSometi{
        width: 100%;
    }



    .startdiv1{
        width: 100%;
        max-width: 100%;
        margin:0;
        margin-bottom: 30px;
        margin-top: 40px;
    }

    .startdiv2{
        width:100%;
        flex-direction: column;
        align-items: center;
        padding-bottom: 20px;
        padding-top: 20px;
    }

    .startimgContainer{
        margin:0;    
        width: 100%;
        padding: 0;
        align-items: center;
    }
}



