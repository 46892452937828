.container{
    display: flex;
    flex-direction: row;
}

.chatList{
    width: 250px;
    height:520px;
    border: 1px solid grey;
    margin: 10px;
    display: flex;
    flex-direction: column;
    overflow: scroll;
    max-height: 100%;
    scroll-behavior: smooth;
    overflow-x: hidden;
}


.dateContainer{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}


.text {
    flex-grow: 1;
    text-align: center;
}


.dateButton{
    margin-bottom: 5px;
    margin-right: 3px;
    cursor: pointer;
}

.oneDayChats div:first-of-type {
    border-top: 1px solid grey;
}

.oneChat{
    width: 100%;
    min-height: 30px;
    border-bottom: 1px solid grey;
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 5px;
    font-size: 15px;
}


.currentChat{
    width: 350px;
    height:520px;
    margin: 10px;
    overflow: scroll;
    max-height: 100%;
    scroll-behavior: smooth;
}


.chatFinal{
    max-width: 80%;
    padding: 10px;
    margin: 15px;
    background-color: #f2f2f2;
    border-radius: 10px;
}

.oneChatassistant{
    width: 100%;
    display: flex;
    font-size: 13px;
    text-align: start;
}

.oneChatassistant2{
    width: 100%;
    display: flex;
    font-size: 13px;
    text-align: start;
}

.oneChatuser{
    width: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    font-size: 13px;
    text-align: start;
}

.demoBox{
    display: flex;
    align-items: center;
    justify-content: center;
}