.forgotButton{
    margin-top: 20px;
    margin-bottom: 20px;
    height: 29px;
    width: 75px;
    color: white;
    background-color: #041F3E;
    border-radius: 10px;
    border: 1px solid black;
    font-family: "Poppins";
    font-size: 15px;
    cursor: pointer;
    margin-left: 10px;
}



.forgotemail{
    padding-top: 20px;
    font-family: "Poppins";
    margin-right: 10px;
}

.forgotInput{
    margin-top: 20px;
    width: 200px;
    height: 21px;
}


.forgotContainer{
    margin-top:320px;
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    color: #041F3E;;
}

.forgotContainer2{
    display: flex;
    flex-direction: column;
    width: 400px;
    align-items: center;
    color: #041F3E;;
    border: 1px solid #041F3E;
    justify-content:flex-start;
    height: 110px;
}


.forgotCont2{
    display: flex;
    flex-direction: row;
}


.forgotEmailSent{
    padding: 25px;
    border: 2px solid #041F3E;
    color: #041F3E;;
    width: 40%;
    font:"Poppins";
}

.forgotEmailSentContainer{
    width: 100%;
    margin-top: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 200px;

}