.Why2 h3{
    color: #041F3E;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 67px;
    font-family: "Poppins";
    font-weight: 430;
    line-height: 1.1em;   
    padding-bottom: 40px;
    margin: 0;
}

.Why2{
    margin-top: 90px;
}
.Why2Body{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap:70px;

}

.Console{
    height: 500px;
    width: 260px;
    background-color: #041F3E;
    border-radius: 20px;
}

.ConsoleHeader{
    height: 90px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 50px;
    font-family: "Poppins";
    font-weight: 400;
}

.consoleTextArea{
    display: flex;
    flex-direction: column;
    color: white;
    gap: 7px;
    font-family: "Poppins";
    width: 100%;
    font-size: 15px;
    height: 65%;
}

.consoleText{
    display: flex;
    text-align: left;
    margin-left: 6px;
    line-height: 2em;
}
.consoleTextContainer{
    display: flex;
    flex-direction: row;
    width: 90%;
    align-items: flex-start;
}

.containerLogo{
    margin-left: 8px;
    margin-top: 3.5px;
}

.ConsoleIcon{
    color: white;
    margin-top: 20px;
    transform: scale(2.5);

}
.consoleFooter{
    height: 10%;

}

@media (max-width: 880px) {
    .ConsoleHeader{
        font-size: 5vw;
    }
    .consoleTextArea{
        font-size: 1.7vw;
    }
}

@media screen and (max-width: 720px) {

    .Why2Body{
        flex-direction: column;
        padding-top: 20px;
    }
    .Console{
        height: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .ConsoleIcon{
        margin-top: 30px;
        margin-bottom: 30px;

    }
    .Why2{
        margin-top: 40px;
        display: flex;
        flex-direction: column;
    }
    .ConsoleHeader{
        font-size: 32px;
    }
    .consoleTextArea{
        font-size: 12px;
    }
    .containerLogo{
        margin-top: 0px;
    }
    .Why2 h3{
        font-size: 50px;
    }

}