.TuoteKehitys{
    margin-top: 80px;
    width: 100%;
    border-top: 2px solid black;
    border-bottom: 2px solid black;
    display: flex;
    flex-direction: row;
    padding-bottom: 20px;
}

.TuoteKehitysLeft{
    text-align: start;
    width: 60%;
    margin-left: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;

}
.TuoteKehitysRight{
    width: 40%;
}

.TuoteKehitysLeftTitle{
    margin-top: 10px;
    font-size: 34px;
    font-family: "Poppins";
    font-weight: 415;
    line-height: 1.1em;
    width: 71%;
    padding: 10px;
    color:#041F3E;

}


.TuoteKehitysLeftText{
    margin-top: 30px;
    font-size: 20px;
    font-family: "Poppins";
    width: 70%;
    padding: 10px;
    color: #041F3E;
    font-weight: 370;

}

.TuoteKehitysButton{
    padding: 8px;
    background-color: #041F3E;
    width: 90px;
    color: white;
    border-radius: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-family: "Poppins";
    margin: 5px;
    margin-bottom: 50px;
}

.handShake{
    color: #041F3E;
    transform: scale(20) translateY(50%);

}


@media screen and (max-width: 720px) {

    .TuoteKehitys{
        display: flex;
        flex-direction: column;
        margin-top: 20px;
    }

    .TuoteKehitysLeft{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin: 0;
        width:92%;
        margin-left: 5px;
    }
    .TuoteKehitysRight{
        position: initial;
        width: 100%;
        padding-bottom: 20px;
    }

    .TuoteKehitysLeftText{
        font-size: 15px;
        width: 90%;
    }

    .TuoteKehitysLeftTitle{
        width: 100%;
        text-align: center;
        font-size: 22px;
    }

    .handShake{
        margin-top: 20px;
        transform: scale(3);
        color: #041F3E;
        display: none;
    }
    .TuoteKehitysButton{
        margin: 0;
        margin-top: 20px;
        position: initial;
        
    }

}