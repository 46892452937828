.Footer{
    background-color: #041F3E;
    width: 100%;
    display: flex;
    flex-direction: row;
    padding-bottom: 20px;
    margin-top: auto;
    color: white;
}

.icon{
    transform: scale(1.8);
    margin-left: 10px;
}

.icon:first-child{
    margin-top: 30px;
}

.firstHalf{
    display: flex;
    width: 50%;
    flex-direction: column;
    align-items: center;
    font-family: "Poppins";

}
.secondHalf{
    display: flex;
    flex-direction: column;
    gap: 40px;
    align-items: flex-start;
    width: 50%;
}

.secondHalf a{
    color: white;
}

.privacy{
    cursor: pointer;
}

.footerLogoContainer img{
    width: auto;
    height: 250px;
}
.footerLogoContainer{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: center;
    flex-direction: column;
    color: white;
}

.tietosuojaselostaText{
    font-size: 16px;
    cursor: pointer;
    text-decoration: underline;
    margin: 6px;
}


@media screen and (max-width: 720px) {
    .Footer{
        flex-direction: column-reverse;
        align-items: center;
    }
    .secondHalf{
        align-items: flex-start;
        justify-content: flex-start;
    }
    .footerLogoContainer img{
        margin-top: 20px;
        margin-bottom: 20px;
    }
    .privacy{
        margin-top: 10px;
        margin-bottom:20px;
    }
}