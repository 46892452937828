.ChatBotsWindow{
    text-align: center;
    width: 100%;
    display: flex;
    flex-direction: column;
}
.chatbotTableHolder{
    padding-top: 140px;
}
.chatBotsTable{
    display: flex;
    flex-wrap: wrap;
    margin-left: 5%;

}
.oneTable{
    border: 2px solid black;
    width: 45%;
    margin: 10px;
    font-family: "Poppins";

}

.tabletitle2{
    font-family: "Poppins";
    font-size: 35px;
    font-weight: 450;
}

.tableItems{
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
    gap:10px
}

.tableRow{
    display: flex;
    flex-direction: row;
    padding: 10px;
    align-items: center;
    margin-left: 18px;
}

.tableText{
    margin-left: 20px;
    font-size: 18px;
    text-align: start;
}

.tableTitle{
    font-size: 20px;
    font-weight: 450;
    margin-bottom: 10px;
    margin-top: 10px;
}

.vsHeadline{
    width: 100%;
    height: 30px;
    font-family: "Poppins";
    font-size: 50px;
    font-weight: 450;
    margin-top: 80px;
    margin-bottom: 100px;

}
.oneTable2{
    border: 2px solid black;
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 34px;
    border-radius: 20px;
    background-color: #041F3E;

    
}
.table2{
    display: flex;
    flex-direction: row;
    gap: 19px;
    justify-content: center;
    margin-left: 20px;
    margin-right: 20px;

}


.tableRow2{
    display: flex;
    flex-direction: row;
    font-family: "Poppins";
}


.tableText2{
    margin-left: 10px;
    color: white;
    text-align: start;
}
#tableicon0{
    color: green;
}

#tableicon1{
    color: white;
}
#tableicon2{
    color: red;
}
.icon22{
    transform: scale(1.5);
    min-width: 20px;

}
.icon23{
    transform: scale(1.5);
    min-width: 20px;

}
.icon20{
    transform: scale(1.4);
    min-width: 20px;

}


.icon24{
    transform: scale(1.8);
    min-width: 20px;
    margin-right: 4px;

}

#oneTable1{
    background-color: #041F3E;
    color: white;

}
#oneTable2{
    background-color: #041F3E;
    color: white;
}

.lastIcon{
    min-width: 20px;
    margin-top: 4px;
}


@media screen and (max-width: 720px) {
    .chatBotsTable{
        flex-wrap:nowrap;
        flex-direction: column;
        margin: 0;
        align-items: center;
    }

    .chatbotTableHolder{
        width: 100%;
    }

    .table2{
        flex-direction: column;

    }
    .oneTable{
        width: 80%;
    }

    .vsHeadline{
        font-size: 25px;
    }
}