.LoginWholePage{
    display: flex;
    flex-direction: column;
    width: 100%;
}

.LoginPage{
    height: 750px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: "Poppins";
}

.loginTab{
    height: 400px;
    width: 350px;
    border: 2px solid black;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.loginText{
    width: 100%;
    height: 20%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.loginTabInner{
    display: flex;
    flex-direction: column;
    width: 80%;
    height: 90%;
}

.loginButton{
    margin-top: 30px;
    height: 40px;
    width: 90%;
    margin-left: 50%;
    transform: translateX(-50%);
    color: white;
    background-color: #041F3E;
    border-radius: 10px;
    border: 1px solid black;
    font-family: "Poppins";
    font-size: 17px;
    cursor: pointer;
}


.loginPassword{
    margin-top: 32px;
    margin-bottom: 10px;

}

.loginInput{
    height: 30px;
}

.loginEmail{
    margin-bottom: 10px;
}



.loginError{
    width: 100%;
    height: 40px;
    text-align: center;
    color: red;
    display: flex;
    align-items: center;
    justify-content: center;

}
.forgot{
    text-align: center;
    color: blue; 
    text-decoration: underline; 
    cursor: pointer; 
    font-size: 15px;
}
.forgot:hover {
    color: darkblue;
    text-decoration: underline; 
}