header{
    background-color: #041F3E;
    width: 100%;
    height: 64px;
    display: flex;
    flex-direction: row;
    position: fixed;
    z-index: 20;
    color: white;
    gap: 20px;
    align-items: center;
    top:-4px;
}

.headerLogIn svg{
    margin-right: 5px;
    transform: scale(1.19);
}

.Headerlogo{
    margin-left: 20px;
    height: 40px;
    cursor: pointer;
}

.headerLogIn{
    margin-right: 10px;
    cursor: pointer;
    height: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    padding: 10px;
    position: absolute;
    right: 10px;
}

.headerLogIn:hover{
    background-color:#031830;
}

.headerButton:hover{
    background-color:#031830;
}

.headerButton{
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    gap:5px;
    height: 50%;
    border-radius: 12px;
    padding: 10px;
    position: relative;
    white-space: nowrap;
}


header a{
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    gap:5px;
    height: 50%;
    border-radius: 12px;
    padding: 10px;
    position: relative;
    white-space: nowrap;
    text-decoration:none;
    color: white;
}


@media screen and (max-width: 720px) {
    .Headerlogo{
        margin-left: 10px;
        height: auto;
        width: 44px;
        margin-right: 10px;
    }
    header{
        gap: 0;
        font-size: 2.4vw;
    }
    .headerLogIn{
        width: 19%;
        padding: 0;
        margin: 0;
    }

    .headerButton{
        gap: 2px;
        width: 19%;
        padding:1px;
        position: relative;
        right: initial;
        height: 34px;
    }

}