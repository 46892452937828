

.privacy1{
    width: 100%;
    min-height: 900px;
    padding-top: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.privacy2{
    width: 70%;
    display: flex;
    flex-direction: column;
    min-height: 900px;
    padding-bottom: 100px;
}

.privacyHeader{
    font-size: 35px;
    font-weight: 500;
}


.margleft{
    margin-left: 20px;
    display: flex;
    flex-direction: column;
    gap:3px;
}

.bold{
    font-weight: 900;
}

.underline{
    text-decoration-line: underline;
}

.fullHeadLine{
    font-weight: 500;
    font-size: 19px;
}