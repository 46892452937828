.tuoteKehitysWindow {
    display: flex;
    flex-direction: column;
    text-align: center;
}

.tuoteKehitysContent {
    min-height: 500px;
    margin-top: 100px;
    padding-top: 70px;
    display: flex;
    flex-direction: row;
    background-color: #041F3E;
    font-family: "Poppins";
    color: white;
}

.leftPanel {
    min-width: 35%;
}

.iconStyle {
    transform: scale(15) translateY(90%);
}

.titleText {
    font-size: 28px;
}

.contentArea {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 30px;
}

.contentHeader {
    font-size: 32px;
    padding: 10px;
    font-weight: 410;
}

.textContent {
    width: 70%;
    font-size: 18px;
    text-align: start;
    font-weight: 400;
}


@media screen and (max-width: 720px) {
    .tuoteKehitysContent{
        flex-direction:column ;
    }
    .leftPanel{
        min-height: 250px;
    }
    .iconStyle {
        transform: scale(8) translateY(120%);
    }
}